import { Button, Container, Grid, Paper, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { ClipLoaderSpinner, CustomBreadcrumbs, SnackbarWrapper } from '../../components'
import { AppState } from '../../reducers'

import { getAccountInfo, resetContent, updateContent } from '../../actions'
import { FEAT_CSS_APP_EDIT } from '../../constants/Roles'
import routes, { HOME_PAGE_ROUTE } from '../../constants/Routes'
import { getErrorMessage, useIsMount, userHasFeaturePermission } from '../../helpers'

const messages = defineMessages({
  home: {
    id: 'home',
    defaultMessage: 'Home',
  },
  customizeApplication: {
    id: 'customize-application',
    defaultMessage: 'Customize application',
  },
  titleSetCssVarsApplication: {
    id: 'title-config-set-css-vars',
    defaultMessage: 'Set css variables',
  },
  btnSave: {
    id: 'save',
    defaultMessage: 'Save',
  },
  titleConfigCssFile: {
    id: 'title-config-css-file',
    defaultMessage: 'Set your custom css',
  },
  titleConfigFont: {
    id: 'title-config-font',
    defaultMessage: 'Set your custom font urls (separated with #)',
  },
  errorConfigFont: {
    id: 'error-config-font',
    defaultMessage: 'Enter valid URLs separated by #',
  },
  genericTextRequired: {
    id: 'generic-text-required',
    defaultMessage: 'This field is required',
  },
  updateAccountSuccessful: {
    id: 'update-account-successful',
    defaultMessage: 'Great, account has been updated',
  },
  margin_left_item_sub_menu: {
    id: 'margin-left-item-sub-menu',
    defaultMessage: 'Margin left, item submenu',
  },
  content_padding: {
    id: 'content-padding',
    defaultMessage: 'Content padding',
  },
  menu_position: {
    id: 'menu-position',
    defaultMessage: 'Menu position',
  },
  menu_background_color: {
    id: 'menu_background-color',
    defaultMessage: 'Menu background color',
  },
  right: {
    id: 'right',
    defaultMessage: 'Right',
  },
  left: {
    id: 'left',
    defaultMessage: 'Left',
  },
})

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  textField: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  wrapperSelect: {
    marginTop: '16px',
    martinLeft: '8px',
    martinRight: '8px',
    martinBottom: '8px',
  },
}))

export const CustomizeApplicationPage = () => {
  const classes = useStyles({})
  const [isValid, setIsValid] = React.useState(true)
  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Custom hooks
   */

  const isMount = useIsMount()

  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    currentAccount: { account },
    content: { update },
    userSession: { token, basic_info_account, permissions },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */

  const [form_state, setFormState] = useState<any>({
    css_text: '',
    fonts_text: '',
    css_vars: {
      margin_left_item_sub_menu: '',
      menu_background_color: '',
      content_padding: '',
    },
    config_vars: {
      menu_position: 'left',
    },
    submitted: false,
  })

  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'error',
    message: '',
    open: false,
  })

  useEffect(() => {
    if (account) {
      if (account.cssvars_application) {
        setFormState((prev_state: any) => {
          return { ...prev_state, css_vars: { ...account.cssvars_application } }
        })
      }

      if (account.config_application) {
        setFormState((prev_state: any) => {
          return { ...prev_state, config_vars: { ...account.config_application } }
        })
      }

      if (account.customcss_application) {
        setFormState((prev_state: any) => {
          return { ...prev_state, css_text: account.customcss_application }
        })
      }

      if (account.customfonts_application) {
        setFormState((prev_state: any) => {
          return { ...prev_state, fonts_text: decodeURI(account.customfonts_application) }
        })
      }
    }

    return () => {
      dispatch(resetContent())
    }
  }, [account, dispatch, token])

  useEffect(() => {
    if (
      isMount &&
      update.loaded &&
      !update.loading &&
      !update.error &&
      account &&
      basic_info_account
    ) {
      dispatch(getAccountInfo(basic_info_account.url))
      setAlertInfo({
        open: true,
        type: 'success',
        message: formatMessage(messages.updateAccountSuccessful),
      })
    } else if (update.error) {
      setAlertInfo({ open: true, type: 'error', message: getErrorMessage(update.error) })
    }
  }, [dispatch, update, account, formatMessage, isMount, basic_info_account])

  /**
   * Component functions
   */

  const { css_text, css_vars, config_vars, fonts_text } = form_state

  const handleClose = () => {
    setAlertInfo((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    setFormState((prev_state: any) => {
      return { ...prev_state, submitted: true }
    })

    if (account) {
      const objToSend = {
        cssvars_application: {
          ...css_vars,
        },
        config_application: {
          ...config_vars,
        },
        customcss_application: css_text,
        customfonts_application: encodeURI(fonts_text),
      }
      dispatch(updateContent(`db/${account['@name']}/`, objToSend))
    }
  }

  const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = e.target as HTMLInputElement
    const splitName = name.split('.')

    if (splitName.length === 1) {
      if (name === 'fonts_text') {
        setIsValid(value.length > 0 ? !value.split('#').some((val) => !validateUrls(val)) : true)
      }
      setFormState((prev_state: any) => {
        return { ...prev_state, [name]: value }
      })
    } else {
      const valueObj = { [splitName[1]]: value }
      setFormState((prev_state: any) => {
        return { ...prev_state, [splitName[0]]: { ...prev_state[splitName[0]], ...valueObj } }
      })
    }
  }

  /**
   * Render
   */

  const btnSubmit = () => {
    if (!update.loading && userHasFeaturePermission(FEAT_CSS_APP_EDIT, permissions)) {
      return (
        <Button variant="contained" color="primary" type="submit">
          {formatMessage(messages.btnSave)}
        </Button>
      )
    }
  }

  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
    ]

    return (
      <CustomBreadcrumbs
        links={links}
        currentPageName={formatMessage(messages.customizeApplication)}
      />
    )
  }

  const validateUrls = (value) => {
    const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(:[0-9]{1,5})?(\/[^\s]*)?$/
    return urlPattern.test(value)
  }

  return (
    <Container maxWidth="lg">
      {renderBreadcrumbs()}
      <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleClose} />
      <form noValidate onSubmit={handleSubmit}>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              {formatMessage(messages.titleSetCssVarsApplication)}
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="margin_left_item_sub_menu"
                  name="css_vars.margin_left_item_sub_menu"
                  label={formatMessage(messages.margin_left_item_sub_menu)}
                  fullWidth
                  margin="normal"
                  value={css_vars.margin_left_item_sub_menu}
                  onChange={handleChange}
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="menu_background_color"
                  name="css_vars.menu_background_color"
                  label={formatMessage(messages.menu_background_color)}
                  fullWidth
                  margin="normal"
                  value={css_vars.menu_background_color}
                  onChange={handleChange}
                  className={classes.textField}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="content_padding"
                  name="css_vars.content_padding"
                  label={formatMessage(messages.content_padding)}
                  fullWidth
                  margin="normal"
                  value={css_vars.content_padding}
                  onChange={handleChange}
                  className={classes.textField}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              {formatMessage(messages.titleConfigFont)}
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={2}
                  rowsMax="25"
                  id="fonts_text"
                  name="fonts_text"
                  placeholder={formatMessage(messages.titleConfigFont)}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={form_state.fonts_text}
                  onChange={handleChange}
                  error={!isValid}
                  helperText={!isValid ? formatMessage(messages.errorConfigFont) : ''}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              {formatMessage(messages.titleConfigCssFile)}
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={6}
                  rowsMax="25"
                  id="css_text"
                  name="css_text"
                  placeholder={formatMessage(messages.titleConfigCssFile)}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={form_state.css_text}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Paper>

          <Grid container>
            <ClipLoaderSpinner loading={update.loading} />
            {btnSubmit()}
          </Grid>
        </main>
      </form>
    </Container>
  )
}
